import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash.capitalize';
import type { JSX, ReactElement } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { type Campaign, CampaignLabelMap } from '@feathr/blackbox';
import { Chip, Label, Time, TimeRange } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import { useUser } from '../../state';
import { campaignColorMap, campaignIconMap, campaignStateColorMap } from '../../styles/campaign';

import styles from './CampaignDatesAndChips.css';

interface ICampaignDatesAndChipsProps {
  campaign: Campaign;
  hideDates?: boolean;
}

function CampaignDatesAndChips({
  campaign,
  hideDates = false,
}: Readonly<ICampaignDatesAndChipsProps>): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();

  const cls = campaign.get('_cls');
  const state = campaign.get('state');
  const timezone = user.get('timezone');

  function delimiter(from: ReactElement, to: ReactElement): JSX.Element {
    return (
      <div className={styles.timeRange}>
        <span className={styles.time}>
          <Label className={styles.label}>{t('Start date')}</Label>
          {from}
        </span>
        <span className={styles.time}>
          <Label className={styles.label}>{t('End date')}</Label>
          {to}
        </span>
      </div>
    );
  }

  function getCampaignDate(campaign: Campaign, timezone: string): JSX.Element {
    /*
     * Single Send campaigns are also email campaigns.
     * We check for them first because they have a start date. The other types have a date range.
     */
    if (campaign.isSingleSend) {
      return (
        <Time
          className={styles.daterange}
          format={TimeFormat.shortWeekdayDateTime}
          prefix={t('Start date')}
          timestamp={campaign.get('date_send_start')}
          timezone={timezone}
        />
      );
    }

    if (campaign.isEmail) {
      return (
        <TimeRange
          className={styles.daterange}
          delimiter={delimiter}
          end={campaign.get('date_send_end')}
          format={TimeFormat.shortWeekdayDateTime}
          start={campaign.get('date_send_start')}
          timezone={timezone}
        />
      );
    }

    if (campaign.isAdCampaign) {
      return (
        <TimeRange
          className={styles.daterange}
          delimiter={delimiter}
          end={campaign.get('date_end')}
          format={TimeFormat.shortWeekdayDateTime}
          start={campaign.get('date_start')}
          timezone={timezone}
        />
      );
    }

    if (campaign.isLandingPageCampaign) {
      return (
        <Time
          className={styles.daterange}
          format={TimeFormat.shortWeekdayDate}
          prefix={t('Start date')}
          timestamp={campaign.get('date_start')}
          timezone={timezone}
        />
      );
    }

    // Handle non-email campaign types
    return (
      <TimeRange
        className={styles.daterange}
        delimiter={delimiter}
        end={campaign.get('date_end')}
        format={TimeFormat.shortWeekdayDate}
        start={campaign.get('date_start')}
        timezone={timezone}
      />
    );
  }

  return (
    <div className={styles.metadata}>
      {!hideDates && getCampaignDate(campaign, timezone)}
      <div className={styles.chips}>
        <Chip
          name={'campaign-type'}
          prefix={<FontAwesomeIcon icon={campaignIconMap.get(cls)!} />}
          theme={campaignColorMap.get(cls)}
        >
          {CampaignLabelMap.get(cls) || t('Campaign')}
        </Chip>
        <Chip name={'campaign-status'} theme={campaignStateColorMap.get(state)}>
          {capitalize(state)}
        </Chip>
      </div>
    </div>
  );
}

export default CampaignDatesAndChips;
