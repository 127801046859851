import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import { ConfirmModal, toast } from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';

interface ICampaignEnableDisableModalProps {
  campaign: Campaign;
  isDisableModalOpen: boolean;
  closeEnableModal: () => void;
  closeDisableModal: () => void;
  isEnableModalOpen: boolean;
}

function CampaignEnableDisableModal({
  campaign,
  isDisableModalOpen,
  isEnableModalOpen,
  closeDisableModal,
  closeEnableModal,
}: Readonly<ICampaignEnableDisableModalProps>): JSX.Element {
  const { t } = useTranslation();

  const type = campaign.get('_cls');
  const state = campaign.get('state');
  const isStoppedSmartPinpointCampaign =
    (type === CampaignClass.AutoPinpointEmail || type === CampaignClass.SmartPinpointEmail) &&
    state === CampaignState.Stopped;

  async function toggleEnabled(): Promise<void> {
    try {
      if (isStoppedSmartPinpointCampaign) {
        /*
         * Auto Send Campaigns that are re-enabled send to the full audience due to a bug in Pinpoint
         * TODO: remove this conditional when resolved in pp
         */
        toast(
          t(
            'Auto Send and Smart campaigns cannot be re-enabled. Please clone this campaign instead.',
          ),
          {
            type: 'error',
          },
        );
        return;
      }
      const newState =
        state === CampaignState.Stopped ? CampaignState.Published : CampaignState.Stopped;
      campaign.set({
        is_enabled: newState !== CampaignState.Stopped,
        is_archived: false,
        state: newState,
      });
      await campaign.save();
      toast(
        t('Campaign {{name}} state has been updated to {{state}}.', {
          name: campaign.name,
          state: newState,
        }),
      );

      state === CampaignState.Stopped ? closeEnableModal() : closeDisableModal();
    } catch (error: any) {
      const action = state === CampaignState.Stopped ? t('enabling') : t('disabling');

      toast(
        t('Encountered an error while {{action}}:\n{{- errorMessage}}', {
          errorMessage: error.message,
          action,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  return (
    <>
      <ConfirmModal
        confirmationPhrase={t('DISABLE')}
        confirmButtonText={t('Disable')}
        confirmButtonType={'danger'}
        onClose={closeDisableModal}
        onConfirm={toggleEnabled}
        opened={isDisableModalOpen}
        t={t}
        title={t('Disable Campaign')}
      >
        <CampaignSummary campaign={campaign} layout={'vertical'} showMinimal={true} />
      </ConfirmModal>
      <ConfirmModal
        confirmationPhrase={t('ENABLE')}
        confirmButtonText={t('Enable')}
        confirmButtonType={'success'}
        onClose={closeEnableModal}
        onConfirm={toggleEnabled}
        opened={isEnableModalOpen}
        t={t}
        title={t('Enable Campaign')}
      >
        <CampaignSummary campaign={campaign} layout={'vertical'} showMinimal={true} />
      </ConfirmModal>
    </>
  );
}

export default CampaignEnableDisableModal;
