import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { DripCampaign } from '@feathr/blackbox';
import { Button, FilterCarousel, Toolbar } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import CampaignPage from '../../CampaignPage/CampaignPage';

import * as styles from './PinpointEmailCampaignPage.css';

export interface IProps {
  children: ReactNode;
  title?: ReactNode;
  details?: ReactNode;
}

function PinpointEmailCampaignPage({ children, title, details }: Readonly<IProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const { campaignId } = useParams<{ eventId?: string; campaignId: string }>();
  const { Campaigns } = useContext(StoresContext);
  const campaign = Campaigns.get(campaignId);

  // TODO: Remove fixture when UI for Drip Campaign reports is implemented in https://github.com/Feathr/shrike/issues/3745.
  const items = [
    { content: 'Lorem impsum imperdietium' },
    { content: 'Lorem impsum imperdietium' },
    { content: 'Lorem impsum imperdietium' },
    { content: 'Lorem impsum imperdietium' },
    { content: 'Lorem impsum imperdietium' },
    { content: 'Lorem impsum imperdietium' },
    { content: 'Lorem impsum imperdietium' },
    { content: 'Lorem impsum imperdietium' },
  ];

  const carousel =
    campaign instanceof DripCampaign ? (
      <FilterCarousel items={items} ordered={true} prefix={'All 5 Emails'} />
    ) : undefined;

  const actions = (
    <Toolbar>
      <Button
        link={localUrl(campaign.getItemUrl('edit'))}
        prefix={<FontAwesomeIcon icon={faPencil} />}
      >
        {t('Edit')}
      </Button>
    </Toolbar>
  );

  return (
    <CampaignPage
      actions={actions}
      campaign={campaign}
      headerChildren={carousel}
      title={campaign.name}
    >
      {details}
      <h3 className={details ? styles.title : undefined}>{title}</h3>
      {children}
    </CampaignPage>
  );
}

export default observer(PinpointEmailCampaignPage);
