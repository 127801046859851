import { Observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { IConfig as IBaseConfig, IReportAttributes, TReportKey } from '@feathr/blackbox';
import type { Redirect, ReportModel } from '@feathr/blackbox';
import { Button, ModalV1, Spinner, Toolbar } from '@feathr/components';
import { shortCodeToken } from '@feathr/hooks';

import { StoresContext } from '../../state';
import RedirectCards from './RedirectCards';

import * as styles from './ShareableLinksModal.css';

function getReportURL(
  startDate: string,
  endDate: string,
  mode: 'live' | 'dateWindow',
  config: IBaseConfig,
  documentKey: TReportKey,
  documentId: string,
) {
  const configString = btoa(JSON.stringify(config));
  const searchParams = new URLSearchParams({
    s: startDate,
    e: documentKey === 'e' && mode === 'live' ? 'now' : endDate,
    m: mode === 'live' ? 'l' : 'dw',
    c: configString,
  } as Record<string, string>);
  const queryString = searchParams.toString();
  return `${BLACKBOX_SHORT_URL}reports/${documentKey}/${documentId}?${queryString}`;
}

interface IProps<IAttributes extends IReportAttributes, IConfig extends IBaseConfig> {
  model: ReportModel<IAttributes>;
  config: IConfig;
  startDate: string;
  endDate: string;
  mode: 'live' | 'dateWindow';
  onClose: () => void;
}

function ShareableLinksModal<IAttributes extends IReportAttributes, IConfig extends IBaseConfig>({
  model,
  config,
  startDate,
  endDate,
  mode,
  onClose,
}: IProps<IAttributes, IConfig>) {
  return (
    <Observer>
      {function useAnonymousFunction() {
        const { Redirects } = useContext(StoresContext);
        const { t } = useTranslation();

        const redirects = Redirects.list({
          filters: { id__in: model.get('report_redirect_ids') },
        });

        async function remove(redirect: Redirect) {
          await Redirects.delete(redirect.id);
          // TODO: Figure out to get model.get() to recognize IAttributes extends IReportAttributes
          const redirectIds: string[] = (model as ReportModel<IReportAttributes>).get(
            'report_redirect_ids',
            [],
          );
          const filteredRedirectIds = redirectIds.filter(
            (redirectId) => redirectId !== redirect.id,
          );
          model.set({
            report_redirect_ids: filteredRedirectIds,
          } as Partial<IAttributes>);
          await model.patchDirty();
        }

        async function addShortLink() {
          const newRedirect = Redirects.create({
            short_code: shortCodeToken(12),
            url: getReportURL(startDate, endDate, mode, config, model.reportKey, model.id),
            proxy: false,
          });
          await Redirects.add(newRedirect, { validate: false });
          const redirectIds = (model as ReportModel<IReportAttributes>).get(
            'report_redirect_ids',
            [],
          );
          redirectIds.push(newRedirect.id);
          model.setAttributeDirty('report_redirect_ids');
          await model.patchDirty();
        }

        return (
          <ModalV1
            cancelButtonText={'Ok'}
            controlled={true}
            ephemeral={true}
            onClose={onClose}
            size={'lg'}
            t={t}
            title={'Get Short Links'}
          >
            <Trans t={t}>
              <p>
                Click "Add Short Link" to create a shortened link to your report. You can customize
                the link to make it memorable and recognizable for whoever you are sharing your link
                with.
              </p>
            </Trans>
            {redirects.isPending ? (
              <Spinner />
            ) : (
              <RedirectCards model={model} redirects={redirects.models} remove={remove} />
            )}
            <Toolbar>
              <Button className={styles.button} onClick={addShortLink}>
                {t('Add short link')}
              </Button>
            </Toolbar>
          </ModalV1>
        );
      }}
    </Observer>
  );
}

export default ShareableLinksModal;
