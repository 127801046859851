import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  Campaign,
  DisplayCreative,
  ICampaignLink,
  ICampaignSegment,
  IParticipation,
  ITrackedLink,
  Targeting,
} from '@feathr/blackbox';
import {
  CampaignClass,
  CampaignLabelMap,
  CampaignState,
  FacebookCampaign,
  PinpointEmailBaseCampaign,
} from '@feathr/blackbox';
import {
  Chip,
  FormSummary,
  FormSummaryItem,
  Skeleton,
  Time,
  TimeRange,
  Value,
} from '@feathr/components';
import { StoresContext, useUser } from '@feathr/extender/state';
import { campaignColorMap, campaignIconMap } from '@feathr/extender/styles/campaign';
import { countries, flattenErrors, moment, TimeFormat } from '@feathr/hooks';
import type { ListResponse, TValidateGrouped } from '@feathr/rachis';

import { CampaignContext } from '../CampaignEditPage/CampaignEdit/campaignEditContext';
import PinpointEmailCampaignAudienceSummary from '../CampaignEditPage/PinpointEmailCampaignAudienceSummary';
import TriggerSummary from './TriggerSummary';

import * as styles from './CampaignSummary.css';

export interface ILinkErrors extends TValidateGrouped {
  link_id?: string[];
  title?: string[];
  url?: string[];
}

export interface ITrackedLinkErrors extends TValidateGrouped {
  url?: string[];
  short_code?: string[];
  utm_source?: string[];
  utm_medium?: string[];
}

export interface IActionErrors extends TValidateGrouped {
  value?: string[];
}

export interface ICampaignValidationErrors extends TValidateGrouped {
  'address.administrative_area_name'?: string[];
  'address.company_name'?: string[];
  'address.country_code'?: string[];
  'address.locality'?: string[];
  'address.postal_code'?: string[];
  'address.premise1'?: string[];
  account?: string[];
  actions?: IActionErrors[];
  budget?: string[];
  cooldown_unit?: string[];
  cooldown_value?: string[];
  contacts_limit?: string[];
  creatives?: string[];
  date_end?: string[];
  date_send_end?: string[];
  date_send_start?: string[];
  date_start?: string[];
  delay_unit?: string[];
  delay_value?: string[];
  duration?: string[];
  facebook?: string[];
  filters?: string[];
  from_address?: string[];
  from_name?: string[];
  goals?: string[];
  links?: ILinkErrors[];
  metadata?: string[];
  monetization_value?: string[];
  name?: string[];
  participation?: string[];
  plain_text_body?: string[];
  redirects?: string[];
  repeat?: string[];
  segments?: string[];
  send_schedule?: string[];
  status?: string[];
  subject?: string[];
  target_segment_id?: string[];
  targets?: string[];
  template?: string[];
  tracked_links?: ITrackedLinkErrors[];
}

interface IProps {
  campaign: Campaign;
  validationErrors?: ICampaignValidationErrors;
  /** Only display the campaign name and type */
  showMinimal?: boolean;
  /** How to format the campaign summary items */
  layout?: 'vertical' | 'horizontal';
}

const TargetsSummary = observer(({ validationErrors }: IProps) => {
  const { t } = useTranslation();
  const { Segments, Targetables } = useContext(StoresContext);
  const { targetings } = useContext(CampaignContext);

  function filterTargetings(trgt: Targeting): boolean {
    return trgt.get('kind') !== 'geo' && !trgt.get('is_archived') && !!trgt.get('target_data');
  }

  const filteredTargetings = targetings?.models.filter(filterTargetings) ?? [];
  const hasTargetings = filteredTargetings.length > 0;
  const targetingListItems = filteredTargetings.map((trgt) => {
    if (trgt.get('kind') !== 'segment') {
      return <li key={trgt.id}>{Targetables.get(trgt.get('target_data')!).get('name')}</li>;
    }
    return (
      <li key={trgt.id}>
        {Segments.get(trgt.get('target_data')!).get('name')} |{' '}
        {trgt.get('included') ? t('Included') : t('Excluded')}
      </li>
    );
  });

  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.targets}
      label={t('Targets')}
      value={
        targetings?.isPending ? (
          <ol>
            <li>
              <Skeleton width={100} />
            </li>
          </ol>
        ) : hasTargetings ? (
          <ol>{targetingListItems}</ol>
        ) : (
          t('(None)')
        )
      }
    />
  );
});

const GeoFiltersSummary = observer(({ validationErrors }: IProps) => {
  const { t } = useTranslation();
  const { GeoFilters, Targetables } = useContext(StoresContext);
  const { targetings } = useContext(CampaignContext);

  function filterTargetings(trgt: Targeting): boolean {
    /*
     * Many types of targetings can belong to a campaign, but we only want geofilter targetings
     * that point to a group (target data).
     */
    return trgt.get('kind') === 'geo' && !trgt.get('is_archived') && !!trgt.get('target_data');
  }

  const filteredTargetings = targetings?.models.filter(filterTargetings) ?? [];
  const hasTargetings = filteredTargetings.length > 0;
  const targetingListItems = filteredTargetings.map((gf) => {
    const trgt = Targetables.get(gf.get('target_data')!);
    if (trgt.get('geo_filter')) {
      const geofilter = GeoFilters.get(trgt.get('geo_filter')!);
      return (
        <li key={gf.id}>
          {(function (): string {
            const address = geofilter.get('address');
            const kind = geofilter.get('kind');
            const name = trgt.get('name');

            if (['Region', 'City'].includes(kind)) {
              return t('{{name}} | {{kind}} | Country: {{country}} | Region: {{region}}', {
                country: address.country,
                kind,
                name,
                region: address.region,
              });
            } else if (['City'].includes(kind)) {
              return t('{{name}} | {{kind}} | Country: {{country}}', {
                country: address.country,
                kind,
                name,
              });
            }
            return t('{{name}} | {{kind}}', { kind, name });
          })()}
        </li>
      );
    } else {
      return;
    }
  });

  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.filters}
      label={t('Filters')}
      value={
        targetings?.isPending ? (
          <ol>
            <li>
              <Skeleton width={100} />
            </li>
          </ol>
        ) : hasTargetings ? (
          <ol>{targetingListItems}</ol>
        ) : (
          t('(None)')
        )
      }
    />
  );
});

const LinksSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.redirects}
      label={t('Links')}
      value={
        campaign.get('tracked_links').length > 0 ? (
          <ol>
            {campaign.get('tracked_links').map((tl: ITrackedLink) => {
              return (
                <li key={tl.redirect_id}>
                  {t('{{url}} | UTM Source: {{source}} | UTM Medium: {{medium}}', {
                    medium: tl.utm_medium,
                    source: tl.utm_source,
                    url: tl.original_url,
                  })}
                </li>
              );
            })}
          </ol>
        ) : (
          t('(None)')
        )
      }
    />
  );
});

const ReferralLinksSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  const links: IObservableArray<ICampaignLink> = campaign.get('links', observable([]));
  return (
    <FormSummaryItem
      className={styles.links}
      label={t('Links')}
      value={
        links.length > 0 ? (
          <ol>
            {links.map((l, i) => {
              const error =
                (validationErrors && validationErrors.links) ||
                ([] as Array<Record<string, string[]>>);
              return (
                <li key={l.link_id}>
                  <Value
                    validationError={error[i] ? flattenErrors(error[i]) : undefined}
                    value={`${l.title} [${l.url || t('missing link')}]`}
                  />
                </li>
              );
            })}
          </ol>
        ) : (
          t('(None)')
        )
      }
    />
  );
});

const CreativesSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  const { Creatives } = useContext(StoresContext);
  const creatives = Creatives.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  }) as ListResponse<DisplayCreative>;
  const filteredCreatives = creatives.models.filter((c) => !c.get('is_archived'));
  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.redirects}
      label={t('Creatives')}
      value={
        creatives.isPending || filteredCreatives.length > 0 ? (
          <ol>
            {creatives.isPending && (
              <li>
                <Skeleton width={100} />
              </li>
            )}
            {filteredCreatives.map((crv) => {
              const spec = crv.getSpec();
              return (
                <li key={crv.id}>
                  {crv.get('name')} | {crv.get('width')}px x {crv.get('height')}px |{' '}
                  {spec ? spec.name : t('unknown')}
                </li>
              );
            })}
          </ol>
        ) : (
          t('(None)')
        )
      }
    />
  );
});

const BudgetSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  const monetization = campaign.get('parent_kind') === 'partner';

  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.budget}
      label={monetization ? t('Target Impressions') : t('Budget')}
      value={numeral(campaign.get('exposure_settings').target_value).format(
        monetization ? '0,0' : '$0,0.00',
      )}
    />
  );
});

const DurationSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();

  const campaignDuration = campaign.isAdCampaign ? (
    <TimeRange
      end={campaign.localEndTime}
      format={TimeFormat.pickerDateTime}
      start={campaign.localStartTime}
    />
  ) : (
    <TimeRange
      end={campaign.get('date_end')}
      format={TimeFormat.shortDate}
      start={campaign.get('date_start')}
    />
  );

  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.duration}
      label={t('Duration')}
      value={campaignDuration}
    />
  );
});

const GoalsSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  const { Goals, Segments } = useContext(StoresContext);
  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const filteredGoals = goals.models.filter((g) => !g.get('is_archived') && !!g.get('segment'));

  return (
    <>
      {campaign.isMonetization && (
        <FormSummaryItem
          errors={validationErrors?.monetization_value}
          label={t('Value')}
          value={'$ ' + numeral(campaign.get('monetization_value')).format('0,0')}
        />
      )}
      {goals.isPending ||
        (filteredGoals.length > 0 && (
          <FormSummaryItem
            errors={validationErrors && validationErrors.goals}
            label={t('Conversion Tracking')}
            value={
              goals.isPending || filteredGoals.length > 0 ? (
                campaign.get('conversion_tracking_mode') === 'auto' ? (
                  <>
                    {t('Goals')}
                    <ol>
                      {goals.isPending && (
                        <li>
                          <Skeleton width={100} />
                        </li>
                      )}
                      {filteredGoals.map((g) => (
                        <li key={g.id}>
                          {Segments.get(g.get('segment')!).get('name')} |&nbsp;
                          {numeral(g.get('conv_value')).format('$0,0.00')}
                        </li>
                      ))}
                    </ol>
                  </>
                ) : (
                  <p>
                    {t('Advanced |')}&nbsp;
                    {filteredGoals.map((g) => (
                      <span key={g.id}>{Segments.get(g.get('segment')!).get('name')}</span>
                    ))}
                  </p>
                )
              ) : (
                t('(None)')
              )
            }
          />
        ))}
    </>
  );
});

const ParticipationSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  const { Partners } = useContext(StoresContext);

  const participation: IParticipation['participation'] = campaign.get('participation', {
    mode: 'campaign',
    partner_ids: [],
  });

  const partners = Partners.list({
    filters:
      participation.mode === 'event'
        ? { participation: campaign.id }
        : { id__in: participation.partner_ids.slice(0, 10) },
    pagination: {
      page_size: 10,
    },
  });

  const count =
    participation.mode === 'manual' ? participation.partner_ids.length : partners.pagination.count;

  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.participation}
      label={t('Partners')}
      value={
        <>
          {participation.mode === 'event' ? (
            <>{t('All partners in this project')}</>
          ) : (
            <>
              {partners.models
                // Cannot sort observable in place, so make a copy using slice().
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((partner, i) => (
                  <Fragment key={partner.id}>
                    {partner.isPending ? (
                      // TODO: Build random width option into Skeleton component
                      <Skeleton width={100 + Math.random() * 60} />
                    ) : (
                      `${partner.name} <${partner.get('email')}>`
                    )}
                    {i < partners.models.length - 1 && <br />}
                  </Fragment>
                ))}
              {count > 10 ? t(' and {{count}} more.', { count: count - 10 }) : ''}
            </>
          )}
        </>
      }
    />
  );
});

const TemplatesSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  const { Templates } = useContext(StoresContext);

  const templateIds: string[] = [
    CampaignClass.PinpointEmail,
    CampaignClass.SmartPinpointEmail,
    CampaignClass.AutoPinpointEmail,
  ].includes(campaign.get('_cls'))
    ? [campaign.get('template_id')]
    : [].concat(
        toJS(campaign.get('banner_templates', [])),
        toJS(campaign.get('email_templates', [])),
        toJS(campaign.get('page_templates', [])),
      );

  const templates = Templates.list({
    filters: {
      id__in: templateIds,
    },
    pagination: {
      page_size: 100,
    },
  });

  return (
    <FormSummaryItem
      errors={validationErrors?.template}
      label={t('Templates')}
      value={
        <>
          {!templateIds.length ? (
            <>({t('None')})</>
          ) : templates.isPending ? (
            templateIds.map((templateId, i) => (
              <Fragment key={templateId}>
                <Skeleton width={100 + Math.random() * 60} />
                {i < templates.models.length - 1 && <br />}
              </Fragment>
            ))
          ) : (
            templates.models
              // Cannot sort observable in place, so make a copy using slice().
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((template, i) => (
                <Fragment key={template.id}>
                  {template.name}
                  {i < templates.models.length - 1 && <br />}
                </Fragment>
              ))
          )}
          {templates.pagination.count > 10
            ? t(' and {{count}} more.', { count: templates.pagination.count - 10 })
            : ''}
        </>
      }
    />
  );
});

const MetadataSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  const hasBannerTemplates = !!campaign.get('banner_templates', []).length;
  const hasPageTemplates = !!campaign.get('page_templates', []).length;

  if (!hasBannerTemplates && !hasPageTemplates) {
    return null;
  }

  const destinationUrl = campaign.get('destination_url');
  const twitterText = campaign.get('twitter_text');
  const twitterHashtags = campaign.get('twitter_hashtags');

  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.metadata}
      label={t('Metadata')}
      value={
        <>
          {hasBannerTemplates && <Value label={'Destination URL'} value={destinationUrl} />}
          {hasPageTemplates && (
            <>
              <Value label={t('Social share text')} value={twitterText} />
              <Value label={t('Social share tags')} value={twitterHashtags} />
            </>
          )}
        </>
      }
    />
  );
});

const SendSummary = observer(({ campaign, validationErrors }: IProps) => {
  const user = useUser();

  const startTimestamp = campaign.get('date_send_start');
  const endTimestamp = campaign.get('date_send_end');
  const userTimezone = user.get('timezone');

  const { t } = useTranslation();
  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.date_send_start}
      label={t('Send Date and Time')}
      value={
        <>
          {campaign.get('send_schedule') === 'now' ? (
            <span>{t('As soon as possible')}</span>
          ) : (
            <>
              <Time
                format={TimeFormat.shortDateTime}
                timestamp={moment.utc(startTimestamp).format(TimeFormat.isoDateTime)}
                timezone={userTimezone}
              />
              {endTimestamp && (
                <>
                  <span> - </span>
                  <Time
                    format={TimeFormat.shortDateTime}
                    timestamp={moment.utc(endTimestamp).format(TimeFormat.isoDateTime)}
                    timezone={userTimezone}
                  />
                </>
              )}
            </>
          )}
        </>
      }
    />
  );
});

const EmailSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.from_address}
      label={t('Email Address')}
      value={campaign.get('from_address')}
    />
  );
});

const AudienceSummary = observer(({ campaign }: IProps) => {
  const { t } = useTranslation();
  const { Segments } = useContext(StoresContext);
  const isAutoSend = campaign.get('_cls') === CampaignClass.AutoPinpointEmail;

  if (!(campaign instanceof PinpointEmailBaseCampaign)) {
    return null;
  }

  const campaignSegments: ICampaignSegment[] = isAutoSend
    ? campaign.get('segments', []).filter((s) => !s.included)
    : campaign.get('segments', [] as ICampaignSegment[]);
  const segments = campaignSegments
    .filter((campaignSegment) => campaignSegment.id !== undefined)
    .map((campaignSegment) => Segments.get(campaignSegment.id!));

  function getLabel(index: number): JSX.Element {
    return (
      <>
        {segments[index].get('name')}&nbsp; | {numeral(segments[index].emails || 0).format('0,0')}{' '}
        {t('Email Addresses')} {!campaignSegments[index].included && '(' + t('excluded') + ')'}
      </>
    );
  }

  if (campaign.get('s3_key')) {
    return <FormSummaryItem label={t('Audience')} value={campaign.get('filename')} />;
  } else {
    return (
      <FormSummaryItem
        label={t('Audience')}
        value={
          segments.length === 1 ? (
            <span>{getLabel(0)}</span>
          ) : (
            <ol>
              {segments.map((segment, i) => (
                <li key={segment.get('name')}>{getLabel(i)}</li>
              ))}
            </ol>
          )
        }
      />
    );
  }
});

const AudienceStatsSummary = observer(({ campaign }: IProps) => {
  const isSmart = campaign.get('_cls') === CampaignClass.SmartPinpointEmail;
  const stats = campaign.get('total_stats');

  if (
    !stats.num_targeted ||
    ![CampaignState.Published, CampaignState.Stopped].includes(campaign.get('state')) ||
    !campaign.isAfterDateSendStart() ||
    (isSmart && !campaign.isAfterDateSendEnd)
  ) {
    return null;
  }

  return <PinpointEmailCampaignAudienceSummary stats={stats} />;
});

const SubjectSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  return (
    <FormSummaryItem
      errors={validationErrors && validationErrors.subject}
      label={t('Email Subject')}
      value={campaign.get('subject')}
    />
  );
});

const ObjectiveSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();

  if (!(campaign instanceof FacebookCampaign)) {
    return null;
  } else {
    return (
      <FormSummaryItem
        errors={validationErrors && validationErrors.subject}
        label={t('Objective')}
        value={campaign.objectiveType}
      />
    );
  }
});

const TargetedCountriesSummary = observer(({ campaign, validationErrors }: IProps) => {
  const { t } = useTranslation();
  const targetedCountries = campaign.get('targeted_countries');

  if (!(campaign instanceof FacebookCampaign)) {
    return null;
  } else {
    return (
      <FormSummaryItem
        errors={validationErrors && validationErrors.subject}
        label={t('Targeted Country', { count: targetedCountries.length })}
        value={
          <ol>
            {targetedCountries
              // Cannot sort observable in place, so make a copy using slice().
              .slice()
              .sort((a, b) => a.localeCompare(b))
              .map((targetedCountry) => (
                <li key={targetedCountry}>{countries[targetedCountry] ?? targetedCountry}</li>
              ))}
          </ol>
        }
      />
    );
  }
});

function getSummaryComponents(cls: CampaignClass): Array<React.FC<IProps>> {
  switch (cls) {
    case CampaignClass.Segment:

    case CampaignClass.Search:

    case CampaignClass.Lookalike:

    case CampaignClass.SeedSegment:

    case CampaignClass.Affinity:

    case CampaignClass.EmailList:
      return [
        TargetsSummary,
        GeoFiltersSummary,
        DurationSummary,
        BudgetSummary,
        CreativesSummary,
        GoalsSummary,
      ];

    case CampaignClass.MobileGeoFencing:

    case CampaignClass.MobileGeoFenceRetargeting:
      return [TargetsSummary, DurationSummary, BudgetSummary, CreativesSummary, GoalsSummary];

    case CampaignClass.TrackedLink:
      return [LinksSummary, GoalsSummary];

    case CampaignClass.Facebook:

    case CampaignClass.EmailListFacebook:
      return [
        ObjectiveSummary,
        TargetedCountriesSummary,
        TargetsSummary,
        DurationSummary,
        BudgetSummary,
        CreativesSummary,
        GoalsSummary,
      ];

    case CampaignClass.Referral:
      return [
        DurationSummary,
        ParticipationSummary,
        TemplatesSummary,
        MetadataSummary,
        ReferralLinksSummary,
        GoalsSummary,
      ];

    case CampaignClass.PinpointEmail:
      return [
        SendSummary,
        EmailSummary,
        SubjectSummary,
        AudienceSummary,
        TemplatesSummary,
        GoalsSummary,
        AudienceStatsSummary,
      ];

    case CampaignClass.SmartPinpointEmail:
      return [
        SendSummary,
        EmailSummary,
        SubjectSummary,
        AudienceSummary,
        TriggerSummary,
        TemplatesSummary,
        GoalsSummary,
        AudienceStatsSummary,
      ];

    case CampaignClass.AutoPinpointEmail:
      return [
        SendSummary,
        EmailSummary,
        SubjectSummary,
        AudienceSummary,
        TriggerSummary,
        TemplatesSummary,
        GoalsSummary,
      ];

    default:
      return [GoalsSummary];
  }
}

function CampaignSummary({
  campaign,
  validationErrors = {},
  showMinimal = false,
  layout = 'horizontal',
}: IProps): JSX.Element {
  const { t } = useTranslation();

  const minimalProps = (
    <>
      <FormSummaryItem
        errors={validationErrors.name}
        label={t('Campaign name')}
        value={campaign.name}
      />
      <FormSummaryItem
        label={t('Type')}
        value={
          <Chip
            prefix={
              <FontAwesomeIcon icon={campaignIconMap.get(campaign.get('_cls')) ?? faBullhorn} />
            }
            theme={campaignColorMap.get(campaign.get('_cls'))}
          >
            {CampaignLabelMap.get(campaign.get('_cls')) ?? 'Campaign'}
          </Chip>
        }
      />
    </>
  );

  if (showMinimal) {
    return <FormSummary layout={'vertical'}>{minimalProps}</FormSummary>;
  }

  return (
    <FormSummary layout={layout}>
      {campaign instanceof FacebookCampaign &&
      validationErrors.facebook &&
      validationErrors.facebook.length ? (
        <FormSummaryItem
          errors={validationErrors.facebook}
          label={t('Meta Integration Status')}
          value={t('Error')}
        />
      ) : (
        <></>
      )}
      {minimalProps}

      <>
        {getSummaryComponents(campaign.get('_cls')).map((SummaryComponent, index) => {
          return (
            <SummaryComponent campaign={campaign} key={index} validationErrors={validationErrors} />
          );
        })}
      </>
      <>
        {validationErrors.account?.map((msg, index) => (
          <div className={styles.error} key={index}>
            {msg}
          </div>
        ))}
      </>
    </FormSummary>
  );
}

export default observer(CampaignSummary);
