import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import type { ValueType } from 'react-select';

import type { Campaign, ICampaignAttributes } from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import { ConfirmModal, toast } from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import EventSelect from '../../EventSelect';
import type { IEventProps } from '../CampaignOptions';

interface ICampaignCloneModalProps {
  campaign: Campaign;
  isCloneModalOpen: boolean;
  closeCloneModal: () => void;
}

function CampaignCloneModal({
  campaign,
  isCloneModalOpen,
  closeCloneModal,
}: Readonly<ICampaignCloneModalProps>): JSX.Element {
  const rootStore = React.useContext(StoresContext);
  const { Events } = rootStore;
  const history = useHistory();
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  const [selectedEvent, setSelectedEvent] = useState<ValueType<IEventProps>>();

  const type = campaign.get('_cls');
  const state = campaign.get('state');
  const isNotDraft = state !== CampaignState.Draft;
  const eventId = campaign.get('event');
  const eventName = eventId ? Events.get(eventId).name : '';

  const flightOrProject = campaign.get('flight') ? t('flight') : t('project');
  const flightOrProjectURL = campaign.get('flight')
    ? localUrl(campaign.getItemUrl())
    : localUrl(`/projects/${campaign.get('parent')}/report`);

  const alertPageLink = (
    <a href={flightOrProjectURL} target={'_blank'}>
      {t('View {{flightOrProject}} report', { flightOrProject })}
    </a>
  );

  useEffect(() => {
    if (!selectedEvent) {
      setSelectedEvent({ project_id: eventId, name: eventName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function clone(): Promise<void> {
    try {
      const { Campaigns, Flights } = rootStore;
      const clonedCampaign: Campaign = await campaign.clone(
        selectedEvent as Partial<ICampaignAttributes>,
      );
      const flightId = campaign.get('flight');
      if (flightId) {
        await Flights.reload(flightId, ['legs']);
      }
      Campaigns.clearApiCache();
      history.push(localUrl(clonedCampaign.getItemUrl()));

      toast(t('Campaign {{name}} has been cloned.', { name: campaign.name }));
      closeCloneModal();
    } catch (error: any) {
      toast(
        t('Encountered an error while cloning:\n{{- errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  function handleChangeEvent(event: ValueType<IEventProps>): void {
    setSelectedEvent(event);
  }

  function handleClearEvent(): void {
    setSelectedEvent(undefined);
  }

  return (
    <ConfirmModal
      alertDescription={
        isNotDraft
          ? t(
              'To recalculate conversions to include the newly cloned campaign, select "Recalculate conversions" from the conversions table on this flight\'s report.',
              { flightOrProject },
            )
          : undefined
      }
      alertLinks={isNotDraft && alertPageLink}
      alertTitle={isNotDraft && t('Are you sure you want to clone this campaign?')}
      cancelButtonText={t('Cancel')}
      confirmButtonText={t('Clone')}
      onClose={closeCloneModal}
      onConfirm={clone}
      opened={isCloneModalOpen}
      t={t}
      title={t('Clone Campaign')}
    >
      <CampaignSummary campaign={campaign} layout={'vertical'} />
      {type === CampaignClass.PinpointEmail && (
        <Observer>
          {(): JSX.Element => (
            <EventSelect
              helpText={t('This campaign will be cloned to the selected project.')}
              id={eventId}
              label={t('Project')}
              onChange={handleChangeEvent}
              onClear={handleClearEvent}
              placeholder={t('Select a project...')}
              value={selectedEvent}
            />
          )}
        </Observer>
      )}
    </ConfirmModal>
  );
}

export default CampaignCloneModal;
