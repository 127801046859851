import { useDisclosure } from '@mantine/hooks';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import { ContextMenu, MenuItem } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import CampaignArchiveModal from './CampaignArchiveModal';
import CampaignCloneModal from './CampaignCloneModal';
import CampaignDeleteModal from './CampaignDeleteModal';
import CampaignEnableDisableModal from './CampaignEnableDisableModal';
import CampaignRemoveFromFlightModal from './CampaignRemoveFromFlightModal';

interface ICampaignOptions {
  campaign: Campaign;
  showFlightOptions?: boolean;
}

export interface IEventProps {
  project_id: string;
  name: string;
}

function CampaignOptions({ campaign, showFlightOptions = false }: ICampaignOptions): JSX.Element {
  const rootStore = React.useContext(StoresContext);
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const [isArchiveModalOpen, { open: openArchiveModal, close: closeArchiveModal }] =
    useDisclosure(false);
  const [isCloneModalOpen, { open: openCloneModal, close: closeCloneModal }] = useDisclosure(false);
  const [isDeleteModalOpen, { open: openDeleteModal, close: closeDeleteModal }] =
    useDisclosure(false);
  const [
    isRemoveFromFlightModalOpen,
    { open: openRemoveFromFlightModal, close: closeRemoveFromFlightModal },
  ] = useDisclosure(false);
  const [isEnableModalOpen, { open: openEnableModal, close: closeEnableModal }] =
    useDisclosure(false);
  const [isDisableModalOpen, { open: openDisableModal, close: closeDisableModal }] =
    useDisclosure(false);

  const state = campaign.get('state');
  const type = campaign.get('_cls');

  const isNotDraft = state !== CampaignState.Draft;
  const isStopped = state === CampaignState.Stopped;
  const isLookalike = type === CampaignClass.Lookalike;
  const isStoppedSmartPinpointCampaign =
    (type === CampaignClass.AutoPinpointEmail || type === CampaignClass.SmartPinpointEmail) &&
    isStopped;

  return (
    <>
      <ContextMenu>
        <StoresContext.Provider value={rootStore}>
          <MenuItem link={localUrl(campaign.getItemUrl('edit'))}>{t('Edit')}</MenuItem>
          <MenuItem
            disabled={isLookalike}
            onClick={openCloneModal}
            tooltip={
              isLookalike &&
              t('This lookalike campaign type is outdated and can no longer be cloned.')
            }
          >
            {t('Clone')}
          </MenuItem>
          {isNotDraft && (
            <MenuItem
              disabled={isStoppedSmartPinpointCampaign}
              onClick={isStopped ? openEnableModal : openDisableModal}
              tooltip={
                isStoppedSmartPinpointCampaign &&
                t(
                  'Auto Send and Smart campaigns cannot be re-enabled. Please clone this campaign instead.',
                )
              }
            >
              {isStopped ? t('Enable') : t('Disable')}
            </MenuItem>
          )}
          {showFlightOptions && (
            <MenuItem onClick={openRemoveFromFlightModal}>{t('Remove from flight')}</MenuItem>
          )}
          <MenuItem onClick={openArchiveModal}>{t('Archive')}</MenuItem>
          {state === CampaignState.Draft && (
            <MenuItem onClick={openDeleteModal}>{t('Delete')}</MenuItem>
          )}
        </StoresContext.Provider>
      </ContextMenu>

      <CampaignCloneModal
        campaign={campaign}
        closeCloneModal={closeCloneModal}
        isCloneModalOpen={isCloneModalOpen}
      />

      <CampaignEnableDisableModal
        campaign={campaign}
        closeDisableModal={closeDisableModal}
        closeEnableModal={closeEnableModal}
        isDisableModalOpen={isDisableModalOpen}
        isEnableModalOpen={isEnableModalOpen}
      />

      <CampaignArchiveModal
        campaign={campaign}
        closeArchiveModal={closeArchiveModal}
        isArchiveModalOpen={isArchiveModalOpen}
      />

      <CampaignDeleteModal
        campaign={campaign}
        closeDeleteModal={closeDeleteModal}
        isDeleteModalOpen={isDeleteModalOpen}
      />

      <CampaignRemoveFromFlightModal
        campaign={campaign}
        closeRemoveFromFlightModal={closeRemoveFromFlightModal}
        isRemoveFromFlightModalOpen={isRemoveFromFlightModalOpen}
      />
    </>
  );
}

export default CampaignOptions;
