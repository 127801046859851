import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { AggregatedStat, EmailBaseCampaign } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { StatBox, StatsCard } from '@feathr/components';

export enum EEmailStats {
  Clicks = 'clicks',
  ClicksPerPerson = 'clicksPerPerson',
  Complaints = 'complaints',
  Deliveries = 'deliveries',
  DeliveryRate = 'deliveryRate',
  Bounces = 'bounces',
  HardBounces = 'hardBounces',
  NotSent = 'notSent',
  Opens = 'opens',
  OpensPerPerson = 'opensPerPerson',
  Sends = 'sends',
  SoftBounces = 'softBounces',
  Targeted = 'targeted',
  UniqueClicks = 'uniqueClicks',
  UniqueClickThroughRate = 'uniqueClickThroughRate',
  UniqueOpenRate = 'uniqueOpenRate',
  UniqueOpens = 'uniqueOpens',
  Unsubscribes = 'unsubscribes',
  UnsubscribeRate = 'unsubscribeRate',
}

export function getEmailStats(stats: AggregatedStat): Record<EEmailStats, number> {
  const {
    pinpoint_tracked_email_click: clicks = 0,
    pinpoint_tracked_email_delivered: deliveries = 0,
    pinpoint_tracked_email_open: opens = 0,
    pinpoint_tracked_email_send: sends = 0,
    pinpoint_tracked_email_suppression: suppressions = 0,
    pinpoint_tracked_email_hardbounce: hardBounces = 0,
    pinpoint_tracked_email_softbounce: softBounces = 0,
    pinpoint_tracked_email_complaint: complaints = 0,
  } = stats.get('flavors');

  return {
    clicks,
    clicksPerPerson: stats.get('clicks_per_person', 0),
    complaints,
    deliveries,
    deliveryRate: stats.get('successful_delivery_rate', 0),
    bounces: hardBounces + softBounces,
    hardBounces,
    opens,
    opensPerPerson: stats.get('opens_per_person', 0),
    sends: sends - suppressions,
    softBounces,
    targeted: stats.get('num_targeted', 0),
    notSent: stats.get('num_not_sent', 0),
    uniqueClicks: stats.get('num_clicks_new', 0),
    uniqueClickThroughRate: stats.get('email_click_through_rate', 0),
    uniqueOpenRate: stats.get('email_open_rate', 0),
    uniqueOpens: stats.get('num_users_new', 0),
    unsubscribes: stats.get('num_persons_unsubscribed', 0),
    unsubscribeRate: stats.get('unsubscribe_rate', 0),
  };
}

interface ICardProps {
  aggregatedStats: AggregatedStat;
  campaign?: EmailBaseCampaign;
}

function EmailStats({ aggregatedStats, campaign }: ICardProps): JSX.Element | null {
  const { t } = useTranslation();

  /*
   * Only for:
   * - CampaignClass.PinpointEmail,
   * - CampaignClass.SmartPinpointEmail,
   * - CampaignClass.AutoPinpointEmail,
   * - CampaignClass.DripCampaign,
   */
  if (!campaign?.isEmail) {
    return null;
  }

  const isSmart = [CampaignClass.SmartPinpointEmail, CampaignClass.AutoPinpointEmail].includes(
    campaign.get('_cls'),
  );

  const {
    clicks,
    clicksPerPerson,
    bounces,
    deliveries,
    deliveryRate,
    hardBounces,
    notSent,
    opens,
    opensPerPerson,
    sends,
    targeted,
    uniqueClicks,
    uniqueClickThroughRate,
    uniqueOpens,
    uniqueOpenRate,
    unsubscribes,
  } = getEmailStats(aggregatedStats);

  function formatNumber(value: number): string {
    return numeral(value).format('0,0');
  }

  function formatPercent(value: number, suffix?: string): string {
    return numeral(value).format('0.00%') + (suffix ? ' ' + suffix : '');
  }

  return (
    <>
      <StatsCard.Content title={t('Delivery')}>
        {targeted > 0 && (
          <StatBox
            label={t('Targeted')}
            primary={
              !isSmart || campaign.isAfterDateSendEnd ? formatNumber(targeted) : formatNumber(0)
            }
            tooltip={t(
              'The total number of people initially targeted based on the combination of included and excluded groups.',
            )}
          />
        )}
        <StatBox
          label={t('Sends')}
          primary={formatNumber(sends)}
          secondary={
            notSent < 0
              ? undefined
              : !isSmart || campaign.isAfterDateSendEnd
                ? formatNumber(notSent)
                : formatNumber(0)
          }
          secondaryLabel={notSent < 0 ? undefined : t('Not sent')}
          tooltip={t(
            'The number of people this email was sent to. Your email will not be sent to targeted persons who previously hard bounced, unsubscribed, or marked your emails as spam.',
          )}
        />
        <StatBox
          label={t('Bounces')}
          primary={formatNumber(bounces)}
          secondary={formatNumber(hardBounces)}
          secondaryLabel={t('Hard bounces')}
          tooltip={t(
            'The number of people who did not receive your email due to a hard or soft bounce.',
          )}
        />
        <StatBox
          label={t('Deliveries')}
          primary={formatNumber(deliveries)}
          secondary={formatPercent(deliveryRate)}
          secondaryLabel={t('Delivery rate')}
          tooltip={t('The number of people who successfully received your email.')}
        />
        <StatBox
          label={t('Unsubscribes')}
          primary={formatNumber(unsubscribes)}
          tooltip={t('The number of people who unsubscribed through this email.')}
        />
      </StatsCard.Content>
      <StatsCard.Content title={t('Performance')}>
        <StatBox
          label={t('Unique opens')}
          primary={formatNumber(uniqueOpens)}
          secondary={formatPercent(uniqueOpenRate)}
          secondaryLabel={t('Open rate')}
          tooltip={t('The total number of people who have opened this email.')}
        />
        <StatBox
          label={t('Total opens')}
          primary={formatNumber(opens)}
          secondary={formatNumber(opensPerPerson)}
          secondaryLabel={t('Opens per person')}
          tooltip={t(
            'The total number of times this email has been opened. Includes multiple opens by the same person.',
          )}
        />
        <StatBox
          label={t('Unique clicks')}
          primary={formatNumber(uniqueClicks)}
          secondary={formatPercent(uniqueClickThroughRate)}
          secondaryLabel={t('Click through rate')}
          tooltip={t('The total number of people who clicked on a link in this email.')}
        />
        <StatBox
          label={t('Total clicks')}
          primary={formatNumber(clicks)}
          secondary={formatNumber(clicksPerPerson)}
          secondaryLabel={t('Clicks per person')}
          tooltip={t(
            'The total number of times that links in this email have been clicked. Includes multiple clicks by the same person.',
          )}
        />
      </StatsCard.Content>
    </>
  );
}

export default observer(EmailStats);
