import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext } from 'react';
import { useParams } from 'react-router';

import type { ReferralCampaign } from '@feathr/blackbox';
import { Button, Tab, Toolbar } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import CampaignPage from '../../CampaignPage/CampaignPage';

import * as styles from './ReferralCampaignPage.css';

export interface IProps {
  children: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
}

function ReferralCampaignPage({ children, description, title }: Readonly<IProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const { campaignId } = useParams<{ eventId?: string; campaignId: string }>();
  const { Campaigns } = useContext(StoresContext);
  const campaign = Campaigns.get(campaignId) as ReferralCampaign;

  const actions = (
    <Toolbar>
      <Button
        link={localUrl(campaign.getItemUrl('edit'))}
        prefix={<FontAwesomeIcon icon={faPencil} />}
      >
        Edit
      </Button>
    </Toolbar>
  );

  const tabs = [
    <Tab exact={true} key={'report'} link={localUrl(campaign.getItemUrl())} title={t('Report')} />,
    <Tab
      key={'templates'}
      link={localUrl(campaign.getItemUrl('templates'))}
      title={t('Templates')}
    />,
    <Tab key={'partners'} link={localUrl(campaign.getItemUrl('partners'))} title={t('Partners')} />,
  ];

  return (
    <CampaignPage actions={actions} campaign={campaign} tabs={tabs} title={campaign.name}>
      <h3>{title}</h3>
      {!!description && <div className={styles.description}>{description}</div>}
      {children}
    </CampaignPage>
  );
}

export default observer(ReferralCampaignPage);
