import { nanoid } from 'nanoid';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CardV2 as Card,
  EmailTemplateSelect,
  InlineEdit,
  Layout,
  StepSection,
} from '@feathr/components';

import PreviewAutomation from './PreviewAutomation';
import PreviewInitialEnrollment from './PreviewInitialEnrollment';

interface IStep {
  id: string;
  isComplete: boolean;
  name: string;
}

function BuilderStep(): JSX.Element {
  const { t } = useTranslation();

  /*
   * TODO: update this rudimentary implementation of the currently editing step id #3703
   * Ultimately, this will probably need to keep track of the current step id that's being edited
   * so only one step is in edit mode at a time.
   */
  const [isEditingStep] = useState<boolean>(false);
  const [steps, setSteps] = useState([
    { id: nanoid(), isComplete: false, name: t('Untitled') },
    { id: nanoid(), isComplete: false, name: t('Untitled') },
  ] as IStep[]);

  function handleAdd(): void {
    setSteps([...steps, { id: nanoid(), isComplete: false, name: t('Untitled') }]);
  }

  function handleDelete(stepIndex: number): () => void {
    return (): void => {
      setSteps(steps.filter((_, i) => i !== stepIndex));
    };
  }

  const initialEnrollmentStep = isEditingStep ? (
    <>
      {/* TODO: Implement editable UI #3703 and integrate the UI #3704 */}
      {/* TODO: Add to <EditInitialEnrollment /> component in #3703 */}
      {/* Editable - Template Selected */}
      <Card.Content>
        <EmailTemplateSelect
          previewUrl={
            'https://local.feathr.app:8002/v1/view_email?cpn_id=66d8d11effa8b02fe83b321c&t_id=66d8d123ffa8b02fe83b321d'
          }
          title={
            'This is the name of a template that is really really long and will not get truncated in edit mode'
          }
        />
      </Card.Content>
    </>
  ) : (
    <PreviewInitialEnrollment />
  );

  const automationStep = isEditingStep ? (
    <>
      {/* TODO: Implement editable UI #3703 and integrate the UI #3704 */}
      {/* TODO: Add to <EditAutomation /> component in #3703 */}
      {/* Editable - Unselected */}
      <Card.Content>
        <EmailTemplateSelect
          description={t('Get started by selecting a template')}
          title={t('No template selected')}
        >
          <Button>{t('Select template')}</Button>
        </EmailTemplateSelect>
      </Card.Content>
    </>
  ) : (
    <PreviewAutomation />
  );

  return (
    <Layout>
      <Card width={'full'}>
        <Card.Header
          description={t(
            'Design the steps of your drip campaign. Each step has an email and automation rules that determine when and how that email should be sent.',
          )}
          title={t('Steps')}
        />
        {/* Do not use addVerticalGap={true} or the step lines will be too short */}
        <Card.Content>
          {steps.map((step, stepIndex) => {
            function handleChangeStepName(value?: string): void {
              setSteps(
                steps.map((s, i) => {
                  if (i === stepIndex) {
                    return { ...s, name: value ?? '' };
                  }
                  return s;
                }),
              );
            }

            return (
              <StepSection
                defaultOpen={true}
                key={step.id}
                onDelete={stepIndex > 0 ? handleDelete(stepIndex) : undefined}
                stepIndex={stepIndex}
                stepNumber={stepIndex + 1}
                // Skip onDelete for the first step
              >
                <StepSection.Header>
                  <InlineEdit onChange={handleChangeStepName} value={step.name} />
                </StepSection.Header>
                <StepSection.Content>
                  <Card theme={'disabled'} width={'full'}>
                    {stepIndex === 0 ? initialEnrollmentStep : automationStep}
                    <Card.Actions>
                      <Button disabled={true}>{t('Edit step')}</Button>
                    </Card.Actions>
                  </Card>
                </StepSection.Content>
              </StepSection>
            );
          })}
        </Card.Content>
        <Card.Actions>
          <Button onClick={handleAdd}>{t('Add step')}</Button>
        </Card.Actions>
      </Card>
    </Layout>
  );
}

export default BuilderStep;
