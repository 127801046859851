import { observable, runInAction, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type { IDynamicContent, Template } from '@feathr/blackbox';
import { DynamicContentClass, TemplateClass } from '@feathr/blackbox';
import { Button, Card, Fieldset, Form, PageHeaderV1, Select } from '@feathr/components';
import type { TDynamicContentResolveHandler } from '@feathr/extender/components/BeeEditor';
import { moment, TimeFormat } from '@feathr/hooks';

import CountdownTimerForm from './CountdownTimerForm';
import FormForm from './FormForm';

import * as styles from './TemplateEditor.css';

interface IProps {
  template: Template;
  resolve?: TDynamicContentResolveHandler;
  reject?: (error?: Error) => void;
  onClose: () => void;
}

const defaultDynamicContentValues: Record<DynamicContentClass, IDynamicContent> = {
  [DynamicContentClass.CountdownTimer]: {
    _cls: DynamicContentClass.CountdownTimer,
    content_index: 0,
    macro_prefix: 'COUNTDOWN_TIMER',
    name: 'Countdown Timer',
    font: 'Arial',
    background_color: 'ffffff',
    color: '000000',
    width: 400,
    frames: 30,
    time: moment.utc().add(30, 'days').format(TimeFormat.isoDate),
  },
  [DynamicContentClass.Form]: {
    _cls: DynamicContentClass.Form,
    content_index: 0,
    macro_prefix: 'FORM',
    name: 'Feathr Form',
    font: 'Arial',
    background_color: 'ffffff',
    color: '000000',
    fields: [],
    title: '',
    subtitle: '',
    complete: 'Thank you!',
    submit: 'Submit',
    action: 'update',
  },
};

interface IDynamicContentOption extends Partial<IDynamicContent> {
  type: 'new' | 'edit';
  id: string;
  _cls: DynamicContentClass;
}

function DynamicContentOption(props: OptionProps<IDynamicContentOption>) {
  return (
    <components.Option {...props}>
      {props.data.type === 'edit' ? `Edit ${props.data.name}` : `Add New ${props.data.name}`}
    </components.Option>
  );
}

function DynamicContentSingleValue(props: SingleValueProps<IDynamicContentOption>) {
  return (
    <components.SingleValue {...props}>
      {props.data.type === 'edit' ? `Edit ${props.data.name}` : `Add New ${props.data.name}`}
    </components.SingleValue>
  );
}

function AddDynamicContentDialog({ template, resolve, reject, onClose }: IProps) {
  const templateDynamicContent: IDynamicContent[] = template.get('dynamic_content', []);
  const numCountdowns = templateDynamicContent.filter(
    (dc) => dc._cls === DynamicContentClass.CountdownTimer,
  ).length;
  const numForms = templateDynamicContent.filter(
    (dc) => dc._cls === DynamicContentClass.Form,
  ).length;
  const nextNumByClass: Record<DynamicContentClass, number> = {
    [DynamicContentClass.CountdownTimer]: numCountdowns,
    [DynamicContentClass.Form]: numForms,
  };
  const dynamicContentOptions: IDynamicContentOption[] = [
    {
      id: template.getDynamicContentMacro({
        _cls: DynamicContentClass.CountdownTimer,
        content_index: nextNumByClass[DynamicContentClass.CountdownTimer],
      }),
      _cls: DynamicContentClass.CountdownTimer,
      name: 'Countdown Timer',
      macro_prefix: 'COUNTDOWN_TIMER',
      content_index: nextNumByClass[DynamicContentClass.CountdownTimer],
      type: 'new',
    },
  ];
  if ([TemplateClass.ReferralPage, TemplateClass.LandingPage].includes(template.get('_cls'))) {
    dynamicContentOptions.push({
      id: template.getDynamicContentMacro({
        _cls: DynamicContentClass.Form,
        content_index: nextNumByClass[DynamicContentClass.Form],
      }),
      _cls: DynamicContentClass.Form,
      name: 'Feathr Form',
      macro_prefix: 'FORM',
      content_index: nextNumByClass[DynamicContentClass.Form],
      type: 'new',
    });
  }
  dynamicContentOptions.push(
    ...template.get('dynamic_content').map(
      (dc: IDynamicContent) =>
        ({
          ...dc,
          id: template.getDynamicContentMacro(dc),
          type: 'edit',
        }) as IDynamicContentOption,
    ),
  );
  const dynamicContent = useRef(
    observable({
      ...defaultDynamicContentValues[DynamicContentClass.CountdownTimer],
      content_index: dynamicContentOptions[0].content_index!,
      name: `Countdown Timer ${numCountdowns}`,
    }),
  ).current;
  const selectedOption = dynamicContentOptions.find(
    (option) =>
      template.getDynamicContentMacro(option as IDynamicContent) ===
      template.getDynamicContentMacro(dynamicContent),
  );
  return (
    <Card className={styles.dialogForm}>
      <Form
        actions={[
          <Button
            key={'cancel'}
            onClick={() => {
              if (reject) {
                reject();
              }
              onClose();
            }}
          >
            Cancel
          </Button>,
          <Button
            key={'confirm'}
            onClick={async () => {
              const existing = template
                .get('dynamic_content')
                .find(
                  (dc: IDynamicContent) =>
                    dc._cls === dynamicContent._cls &&
                    dc.content_index === dynamicContent.content_index,
                );
              if (existing) {
                set(existing, dynamicContent);
                await template.save();
              } else {
                await template.patch({
                  dynamic_content: [...template.get('dynamic_content'), dynamicContent],
                });
              }
              if (resolve) {
                resolve({
                  name: dynamicContent.name,
                  value: template.getDynamicContentMacro(dynamicContent as IDynamicContent),
                });
              }
              onClose();
            }}
            type={'primary'}
          >
            {selectedOption!.type === 'edit' ? 'Save Changes' : 'Add'}
          </Button>,
        ]}
        label={'Send Preview'}
      >
        <PageHeaderV1 className={styles.dialogHeader}>
          <h3>Add/Edit Dynamic Content</h3>
        </PageHeaderV1>
        <Fieldset className={styles.typeSelect}>
          <Select
            components={{
              Option: DynamicContentOption,
              SingleValue: DynamicContentSingleValue,
            }}
            helpText={`
              Choose from your existing dynamic content to edit or choose a type of dynamic content to add.
              The available options depend on the type of template.
            `}
            label={'Select Dynamic Content to Add or Edit'}
            onSelectSingle={(option) => {
              if (option.type === 'new') {
                runInAction(() => {
                  set(dynamicContent, {
                    ...defaultDynamicContentValues[option._cls],
                    content_index: option.content_index,
                    name: `${option.name} ${nextNumByClass[option._cls]}`,
                  } as IDynamicContent);
                });
              } else if (option.type === 'edit') {
                const { type, ...rest } = option;
                runInAction(() => {
                  set(dynamicContent, { ...rest } as IDynamicContent);
                });
              }
            }}
            options={dynamicContentOptions}
            value={selectedOption}
          />
        </Fieldset>
        <section>
          <legend>Configuration</legend>
          {dynamicContent._cls === DynamicContentClass.CountdownTimer && (
            <CountdownTimerForm
              dynamicContent={dynamicContent}
              key={template.getDynamicContentMacro(dynamicContent)}
            />
          )}
          {dynamicContent._cls === DynamicContentClass.Form && (
            <FormForm
              dynamicContent={dynamicContent}
              key={template.getDynamicContentMacro(dynamicContent)}
            />
          )}
        </section>
      </Form>
    </Card>
  );
}

export default observer(AddDynamicContentDialog);
