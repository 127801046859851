/* eslint-disable simple-import-sort/imports */
import mapboxgl from 'mapbox-gl';
import React, { Suspense, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, Redirect } from 'react-router';
import { ToastContainer } from 'react-toastify';

import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@feathr/components/dist/style/theme/index.css';
import '@feathr/components/dist/style/global/index.css';
import * as styles from './App.css';

// Load after theme/index.css.
import type { IStats, User } from '@feathr/blackbox';
import { isSuperpixelActive, Spinner } from '@feathr/components';
import { StoresContext, useAccount, useUser } from '@feathr/extender/state';
import { logUserEvents, moment } from '@feathr/hooks';
import { DefaultLanguage, i18n } from '@feathr/locales';

import Routes from './Routes';
import UserErrorBoundary from '../components/UserErrorBoundary';
import type { JSX } from 'react';
import { NoUserError } from '../state/useUser';

async function addRecent(user: User, accountId: string): Promise<void> {
  // Prepend accountId to recents, and make sure there are no duplicate entries.
  const currentRecents = user.get('recent_account_ids', []).filter((item) => item !== accountId);
  const patch = {
    account: accountId,
    recent_account_ids: [accountId, ...currentRecents].slice(0, 20),
  };
  await user.patch(patch, { headers: user.collection!.getHeaders(false) });
  const recents = user.get('recent_account_ids');

  if (recents.length === 0 || (recents.length >= 1 && recents[0] !== accountId)) {
    throw new Error(
      'There is a problem with Users.patch(); changes to User.recent_account_ids are not getting saved.',
    );
  }
}

function App(): JSX.Element {
  // Prefetch account and user.
  const account = useAccount({ required: false });
  const user = useUser({ required: false });

  /*
   * User is returned as ephemeral on the first render, so we
   * cannot use assertUser
   */
  if (!user || user.isErrored) {
    throw new NoUserError();
  }

  const location = useLocation();
  const [isRecentUpdated, setRecentUpdated] = useState<boolean>(false);
  const { UsageReports } = useContext(StoresContext);

  const userId = user?.id;
  const accountName = account && account.get('name');
  const userName = user?.get('name');
  const usage = UsageReports.get(sessionStorage.getItem('accountId')!);

  React.useEffect(() => {
    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
  }, []);

  React.useEffect(() => {
    window.Appcues?.page();
  }, [location.pathname, location.search, location.hash, location.state]);

  React.useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    if (account && user && user.get('email')) {
      window.dataLayer.push({
        event: 'session:start',
        email: user.get('email', ''),
        name: user.name,
        d_c: account.get('date_created', ''),
        account_id: account.id || '',
      });
    }
  }, [account, account?.isPending, user, user?.isPending]);

  React.useEffect(() => {
    if (account && !account.isPending && user && user.id && usage && !usage.isPending) {
      const stats = account.get('stats', { num_crumbs: 0 } as IStats);
      const hasActiveSuperpixel = isSuperpixelActive(stats.num_crumbs ?? 0);

      const userProperties = {
        'Account ID': account.id,
        Account: account.get('name'),
        'Has active Superpixel': hasActiveSuperpixel,
        'Has services': account.hasServices,
        'Total conversions': stats.num_conversions ?? 0,
        'Total segments': stats.num_segments ?? 0,
        'Plan tier': account.activePackage?.name,
        'User role': user.isAdmin || user.isSudoer ? 'admin' : 'user',
        'Service packages': account
          .get('license')
          .packages.map(({ name }) => name)
          .join(','),
        ...usage.getReport({ labels: true }),
      };

      const licensePrice = account.activePackage?.price ?? 0;
      const totalActiveServicesPrice = account.activeServices?.reduce(
        (acc, service) => acc + service.price,
        0,
      );
      const monthlySpend = (licensePrice + totalActiveServicesPrice) / 12;

      const cannyProps = {
        appID: '66a93e0dff4d55e0e962ecd2',
        user: {
          id: user.id,
          name: user.name,
          created: user.dateCreated,
          email: user.get('email'),
          companies: [
            {
              id: account.id,
              created: account.get('date_created'),
              name: account.get('name'),
              monthlySpend,
              customFields: {
                activeServices: account.activeServices?.map(({ name }) => name).join(','),
                activeServicesPrice: totalActiveServicesPrice,
                csm: account.get('csm'),
                dateLastModified: account.get('date_last_modified'),
                license: account.activePackage?.name,
                licenseStart: account.activePackage?.period.start,
                licenseEnd: account.activePackage?.period.end,
                licensePrice: licensePrice,
              },
            },
          ],
          customFields: {
            lastAccess: user.get('date_last_access'),
            numLogins: user.get('logins'),
            role: user.get('role')?.name,
          },
        },
      };

      /*
       * Identify user in Canny.
       * https://developers.canny.io/install/custom-fields
       */
      window.Canny('identify', cannyProps);

      // Appcues.identify() automatically calls Appcues.page().
      window.Appcues?.identify(
        user.id, // Unique, required
        userProperties,
      );
      window.heap?.addUserProperties(userProperties);
      if (isSuperpixelActive(stats.num_crumbs || 0)) {
        logUserEvents({ [`Superpixel is ${hasActiveSuperpixel ? 'active' : 'inactive'}`]: null });
      }
    }
  }, [user, userId, account, accountName, UsageReports, usage]);

  React.useEffect(() => {
    if (user && !user.isPending) {
      // Appcues.identify() automatically calls Appcues.page().
      window.Appcues?.identify(
        user.id, // Unique, required
        {
          user_id: user.id,
          user_name: user.name,
          email: user.get('email'),
          created_at: moment.utc(user.dateCreated).unix(), // Unix timestamp
          logins_count: user.get('logins'),
        },
      );
      window.heap?.identify(`${user.name} ${user.id}`);
      window.heap?.addUserProperties({
        name: user.name,
        email: user.get('email'),
        created_at: moment.utc(user.dateCreated).unix(),
        role: user.role,
        logins_count: user.get('logins'),
      });
    }
  }, [user, userName]);

  React.useEffect(() => {
    if (user && !user.isPending) {
      const lng = user.getSetting('lng', DefaultLanguage);
      if (i18n.language !== lng) {
        // TODO: User language setting should not override language in querystring
        i18n.changeLanguage(lng);
        document.documentElement.lang = lng;
      }
    }
  }, [user, userName]);

  if (user.isPending || (account && account.isPending)) {
    return <Spinner className={styles.spinner} />;
  } else if (!user.isPending && user.get('account') && !account) {
    // If we have no account context, it means there was no valid account id prefixing the route
    return (
      <Redirect
        to={{
          pathname: `/${user.get('account')}/${location.pathname.split('/').slice(1).join('/')}`,
          state: { from: location },
        }}
      />
    );
  } else if (!user.isPending && account && account.isErrored) {
    /*
     * If the account is errored it probably means we got a 403 when we fetched it, meaning we do not
     * have access to this account - so we should render a 404
     */
    return (
      <Redirect
        to={{ pathname: `/${user.get('account')}/404-not-found`, state: { from: location } }}
      />
    );
  }

  if (account && !account.isEphemeral && account.id && !isRecentUpdated) {
    const recents = user.get('recent_account_ids', []);
    if (account.id !== sessionStorage.getItem('accountId')) {
      // Store accountId in sessionStorage to use in Session.getHeaders().
      sessionStorage.setItem('accountId', account.id);
    }
    if (recents.length === 0 || (recents.length >= 1 && recents[0] !== account.id)) {
      addRecent(user, account.id);
      setRecentUpdated(true);
    }
  }

  return (
    <UserErrorBoundary>
      <Suspense fallback={<Spinner />}>
        <Routes />
        <ToastContainer pauseOnFocusLoss={false} />
      </Suspense>
    </UserErrorBoundary>
  );
}

export default observer(App);
