/* eslint-disable no-console */
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type {
  IPredicate,
  Segment,
  SmartPinpointEmailBaseCampaign,
  TPredicateMode,
} from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardV2 as Card,
  Checkbox,
  Select,
} from '@feathr/components';
import type { IActionErrors } from '@feathr/extender/App/EventsPage/CampaignSummary';
import FilterChip from '@feathr/extender/components/EditFilters/FilterChip';
import Glue from '@feathr/extender/components/Glue';
import { StoresContext } from '@feathr/extender/state';
import { useReactionEffect } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './PinpointTriggersConfig.css';

interface IProps {
  campaign: SmartPinpointEmailBaseCampaign;
  disabled: boolean;
  label?: string;
}

interface IErrors extends TValidateGrouped {
  actions?: IActionErrors[];
}

interface IModeOption extends ISelectOption {
  id: TPredicateMode;
}

function PinpointTriggersConfig({ campaign, disabled, label }: IProps): JSX.Element {
  const { Segments } = useContext(StoresContext);
  const { t } = useTranslation();
  const triggers = campaign.get('actions');
  const mode = campaign.get('mode') ?? 'match_any';
  const [peopleCount, setPeopleCount] = useState<number>(0);
  const kind = triggers.some((trigger) => trigger.kind === 'update') ? 'attribute' : 'activity';

  function validateTriggers(campaign: SmartPinpointEmailBaseCampaign): IErrors {
    return campaign.validate<IErrors>(['actions'], false, 'grouped').errors;
  }

  const validationErrors = validateTriggers(campaign);
  const hasErrors = !!validationErrors.actions?.length;

  // Ensure the segment count includes individuals with email addresses.
  const defaultPredicate: IPredicate = {
    kind: 'attribute',
    attr_against: 'email',
    attr_type: 'string',
    comparison: 'exists',
  };

  /*
   * Ephemeral segment to get the count of people who will receive the email.
   * The default predicate is to ensure we only count people with email addresses.
   * The subsequent predicate group can have a different mode.
   */
  const [segment] = useState<Segment>(() =>
    Segments.create({
      account: campaign.get('account'),
      is_conversion_segment: false,
      is_custom: false,
      lookback_mode: 'unbounded',
      mode: 'match_all',
      predicates: observable.array([
        defaultPredicate,
        {
          group: observable.array(triggers),
          group_mode: campaign.get('mode') ?? 'match_any',
          kind: kind,
        },
      ]),
    }),
  );

  /*
   * If the user sets triggers that don't match any people, we want to uncheck
   * the send all checkbox to prevent sending to no one resulting in an errored Single Send campaign.
   */
  if ((segment.countPromiseState !== 'pending' && segment.count === 0) || triggers.length === 0) {
    campaign.set({ send_all: false });
  }

  const modeOptions: IModeOption[] = [
    { id: 'match_all', name: t('all') },
    { id: 'match_any', name: t('any') },
  ];

  const modeOption = modeOptions.find(({ id }) => id === mode) ?? modeOptions[0];

  // When the triggers change, update the predicates in the segment. This will trigger a re-count of the people in the segment.
  useEffect((): void => {
    const predicates = segment.get('predicates');
    const predicateGroup = predicates[1];
    const group = predicateGroup?.group as IObservableArray | undefined;

    if (group && predicateGroup.group_mode && predicateGroup.kind) {
      // If group, group_mode, and kind exist, replace group, update group_mode, and kind.
      group.replace(observable.array(triggers));
      predicateGroup.group_mode = campaign.get('mode') ?? 'match_any';
      predicateGroup.kind = kind;
    } else {
      // If group or other necessary properties are missing, handle them separately.
      if (!group) {
        // If group is missing, create a new one.
        runInAction((): void => {
          predicateGroup.group = observable.array(triggers);
        });
      }
      if (!predicateGroup.group_mode) {
        // If group_mode is missing, set it based on the campaign mode.
        predicateGroup.group_mode = campaign.get('mode') ?? 'match_any';
      }
      if (!predicateGroup.kind) {
        // If kind is missing, set it to the provided value.
        predicateGroup.kind = kind;
      }
      // If the comparison itself is missing, push a new predicate.
      if (!predicateGroup) {
        runInAction((): void => {
          predicates.push({
            group: observable.array(triggers),
            group_mode: campaign.get('mode') ?? 'match_any',
            kind: kind,
          });
        });
      }
    }
  }, [campaign, kind, segment, triggers]);

  function handleAddTrigger(): () => void {
    return function () {
      campaign.set({
        actions: [
          ...triggers,
          {
            attr_against: undefined,
            kind: 'update',
          },
        ],
      });
    };
  }

  function handleRemove(key: number): () => void {
    return function (): void {
      const newTriggers = triggers.filter((_, i) => i !== key);
      campaign.set({ actions: newTriggers });
    };
  }

  // When the segment count changes, update the people count in the UI.
  useReactionEffect(
    () => {
      return segment.count;
    },
    () => {
      setPeopleCount(segment.count);
    },
  );

  function handleSendBulkEmail(newValue?: boolean): void {
    campaign.set({ send_all: newValue });
  }

  function handleChangeMode(option: ISelectOption): void {
    const newMode = option.id as TPredicateMode;
    campaign.set({ mode: newMode, actions: triggers });

    // Update predicates.
    const predicates = segment.get('predicates');
    const groupMode = predicates[1]?.group_mode;
    runInAction((): void => {
      if (groupMode) {
        // Update group mode if already exists.
        predicates[1].group_mode = newMode;
      } else {
        predicates.push({
          // Add new predicate group mode if it doesn't exist.
          group_mode: newMode,
        });
      }
    });
  }

  return (
    <Card width={'full'}>
      <CardHeader title={label ?? t('Triggers')} />
      <CardContent>
        <div className={styles.header}>
          <Trans t={t}>
            Send an email to people that match{' '}
            <Select
              className={styles.mode}
              // Mode is limited to any when date triggered is selected
              disabled={campaign.get('subtype') === 'time'}
              onSelectSingle={handleChangeMode}
              options={modeOptions}
              value={modeOption}
            />
            of the following filters:
          </Trans>
        </div>
        <div>
          {triggers.map((trigger, index) => {
            const key = `${trigger.attr_against}${index}`;
            const lastItemIndex = triggers.length - 1;
            return (
              <div key={key}>
                {/* TODO: replace with <Predicates> in  #3595 */}
                <FilterChip
                  disabled={disabled}
                  filterContext={'pinpoint'}
                  key={trigger.attr_against}
                  model={campaign}
                  onClickRemove={handleRemove(index)}
                  predicate={trigger}
                  triggers={triggers}
                />
                <Glue isLastItem={index === lastItemIndex} mode={mode} t={t} />
              </div>
            );
          })}
        </div>
      </CardContent>
      <CardActions contentClassName={styles.actions}>
        {!disabled && (
          <>
            <Checkbox
              data-name={'checkbox_bulk_single_send'}
              // If there are no people or there are errors, we want to disable the checkbox.
              disabled={peopleCount === 0 || hasErrors}
              label={t(
                'Send a separate Single Send Campaign to {{ count, number }} person who has previously met these criteria.',
                { count: peopleCount > 0 && !hasErrors ? peopleCount : 0 },
              )}
              name={'bulk_single_send'}
              onChange={handleSendBulkEmail}
              value={campaign.get('send_all')}
            />
            <Button
              name={'add_filter'}
              onClick={handleAddTrigger()}
              prefix={<FontAwesomeIcon icon={faPlus} />}
            >
              {t('Trigger')}
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
}

export default observer(PinpointTriggersConfig);
